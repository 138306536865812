.languageSwitchButton {
  display: flex;
  align-items: center;
  border: none;
  margin: 10px 0px 40px 25px;
  background-color: white;
  /* width: 150px; */
  color: #6f6e7a;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-size: 15px;
}

.languageSwitchButton:hover {
  color: #44434a;
}

span.active {
  color: #1b1b1b;
}