.hondaHubButton {
  background-color: white;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #6f6e7a;
  margin-left: 22px;
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-size: 15px;
  text-align: left;
}
.hondaHubButton :hover {
  color: #44434a;
}
