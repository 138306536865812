.logOutButton {
  background-color: white;
  width: 250px;
  display: flex;
  align-items: center;
  border: none;
  color: #6f6e7a;
  margin-left: 25px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-size: 15px;
}
.logOutButton :hover {
  color: #44434a;
}
